.lightning-page-container {
    width: 100%;
    padding: 1%;
    background-color: rgba(255, 255, 255, 0.95); /* Subtle background */
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    position: relative;
  }
  
  .lightning-content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    background-color: #0940ca;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    animation: smoothFadeIn 1s ease-in-out;
  }
  
  .lightning-icon {
    margin-right: 20px;
  }
  
  .lightning-background {
    position: relative;
    width: 540px;
    height: 330px;
  }
  
  .lightning-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: absolute;
    z-index: 2;
    filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0.8));
  }
  
  .text-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 5%;
    color: aliceblue;
    font-size: 1.2rem;
  }

  .alert-heading {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #ebe9f9;
    display: flex;
    align-items: center;
    animation: textBlink 1.5s infinite alternate;
  }
  
  /* Lightning Icon Glow Effect */
  
  
  .dismiss-button {
    margin-top: 15px;
    padding: 8px 16px;
    background-color: #ffcc00;
    color: black;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s;
  }
  
  .dismiss-button:hover {
    background-color: #143ea7;
  }
  
  /* Animation for glow effect */
  .lightning-glow {
    padding-left: 5%;
    color: #ffcc00;
    text-shadow: 0 0 15px #ffcc00, 0 0 25px #ffcc00, 0 0 50px #ffcc00, 0 0 100px #ffcc00; /* Stronger glow */
    animation: lightningGlow 3s ease-in-out infinite alternate;
    margin-right: 10px; /* Space between icon and text */
  }
  
  /* Increased intensity of the glow animation */
  @keyframes lightningGlow {
    0% {
      text-shadow: 0 0 15px #ffcc00, 0 0 25px #ffcc00, 0 0 50px #ffcc00, 0 0 100px #ffcc00;
    }
    100% {
      text-shadow: 0 0 30px #ffcc00, 0 0 50px #ffcc00, 0 0 75px #ffcc00, 0 0 150px #ffcc00;
    }
  }
  /* Fade-in effect for content */
  @keyframes smoothFadeIn {
    0% {
      opacity: 0;
      transform: translateY(10px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
 
  /* Blinking effect for alert heading */
  @keyframes textBlink {
    0% {
      opacity: 0.8;
    }
    100% {
      opacity: 1;
    }
  }
  