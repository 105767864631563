/* Global Styles */
.weather-widget {
  position: relative;
  width: 100%;
  height: 500px; /* Set a fixed height for the widget */
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: Arial, sans-serif;
  padding: 20px; /* Adjust padding as needed */
  overflow: hidden; /* Ensure content doesn't overflow */
  box-sizing: border-box; /* Include padding in the height calculation */
}

.weather-header h4 {
  margin: 0;
  font-size: 2em;
  font-weight: bold;
}

.weather-carousel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px; /* Adjust margin as needed */
  flex-grow: 1; /* Allow this section to grow and fill available space */
}

.weather-item h2 {
  margin: 0;
  font-size: 1.5em;
}

.weather-item p {
  font-size: 1.2em;
  margin: 0; /* Remove default margin to avoid extra space */
}

/* Rain and Snow Effects */
@keyframes rain {
  0% { top: -10%; }
  100% { top: 100%; }
}

@keyframes snow {
  0% { top: -10%; }
  100% { top: 100%; }
}

.animation-drop {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.7);
  pointer-events: none;
  opacity: 0.8;
}

/* Light rain effect */
.light-rain .animation-drop {
  width: 2px;
  height: 10px;
  background-color: rgba(255, 255, 255, 0.5);
  animation: rain 1s linear infinite;
}

/* Heavy rain effect */
.heavy-rain .animation-drop {
  width: 3px;
  height: 25px;
  background-color: rgba(255, 255, 255, 0.7);
  animation: rain 0.5s linear infinite;
}

/* Drizzle effect */
.drizzle .animation-drop {
  width: 1px;
  height: 5px;
  background-color: rgba(255, 255, 255, 0.3);
  animation: rain 1.5s linear infinite;
}

/* Snow effect */
.snow .animation-drop {
  width: 4px;
  height: 4px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  animation: snow 3s linear infinite;
}

/* Breeze effect */
.breeze .animation-drop {
  width: 5px;
  height: 10px;
  background-color: rgba(255, 255, 255, 0.7);
  animation: rain 2s linear infinite;
}

/* Background for different conditions */
.sunny {
  background: linear-gradient(135deg, #FFD700, #FF8C00);
}

.rainy {
  background: linear-gradient(135deg, #4A90E2, #0073E6);
}

.cloudy {
  background: linear-gradient(135deg, #A8A8A8, #808080);
}

.windy {
  background: linear-gradient(135deg, #56C4D3, #009688);
}

.snowy {
  background: linear-gradient(135deg, #D6EAF8, #AED6F1);
}

#wnumber {
  font-size: 2rem;
  font-weight: bold;
}