.dyn-main {
  display: flex;
  flex-direction: column;
  padding: 10px; /* Reduced padding for less spacing */
  background-color: #f4f7fc;
  margin-top: -50%;
}

.ch1-title {
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 15px; /* Reduced bottom margin for less gap */
  color: #333;
}

.dyn-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap; /* Prevent wrapping to reduce vertical gaps */
  gap: 10px; /* Reduced gap between items */
}

.chart-container {
  flex: 2;
  width: 80%; /* Simplified width */
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.info-section {
  display: flex;
  justify-content: space-between;
  gap: 10px; /* Reduced gap */
  flex: 1;
  flex-wrap: nowrap; /* Prevent wrapping */
  align-items: flex-start; /* Align items at the top */
}

.left-side,
.right-side {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Reduced gap */
  flex: 1;
}

.info-box {
  flex: 1;
  width: 100%; /* Ensure consistent sizing */
  background-color: white;
  padding: 15px; /* Reduced padding */
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.info-box h3 {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 5px; /* Reduced spacing between title and content */
  text-align: center;
}

.info-box p {
  font-size: 1rem; /* Slightly smaller font size */
  color: #333;
  margin-bottom: 5px; /* Reduced spacing between paragraphs */
}

.info-box strong {
  font-weight: bold;
}

/* Responsive Design */
@media (max-width: 768px) {
  .info-section {
      flex-wrap: wrap; /* Allow wrapping on smaller screens */
      gap: 15px; /* Slightly larger gap for readability */
  }

  .info-box {
      width: 100%; /* Full width for smaller screens */
      padding: 10px; /* Reduced padding for compact layout */
  }

  .dyn-content {
      flex-direction: column; /* Stack content vertically */
      gap: 15px; /* Larger gap for smaller screens */
  }

  .chart-container {
      width: 100%; /* Adjust width to fit smaller screens */
  }
}
