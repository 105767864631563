
  
.location-block {
    margin-bottom: 30px; /* Space between each location block */
  }
  
  .ch1-title {
    margin-bottom: 20px; /* Space between title and content */
  }
  
  .dyn-content {
    display: flex;
    justify-content: space-between; /* Space out the content */
    margin-bottom: 20px; /* Space between chart and info sections */
  }
  
  .chart-container {
    margin-right: 20px; /* Add space between chart and info section */
  }
  
  .info-section {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .left-side,
  .right-side {
    width: 48%; /* Adjust the width of the sides */
  }
  
  .info-box {
    margin-bottom: 20px; /* Add space between info boxes */
    padding: 15px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  h3 {
    margin-bottom: 10px; /* Space between heading and content */
  }
  
  h1 {
    margin-top: 0;
    margin-bottom: 10px; /* Space between value and the next line */
  }
  
  h6 {
    color: #555;
  }
  

  .slider-container {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }
  
  .threshold-slider {
    width: 100%;
    margin: 10px 0;
  }
  
  .threshold-slider::-webkit-slider-thumb {
    background-color: #4caf50;
    cursor: pointer;
  }
  
  .threshold-slider::-webkit-slider-runnable-track {
    background: #ddd;
    height: 8px;
    border-radius: 5px;
  }
  
  .threshold-slider:focus {
    outline: none;
  }
  
  p {
    font-size: 16px;
    font-weight: bold;
  }
  