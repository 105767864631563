
.form-container {
    margin: 80px auto;
    width: 70%;
    max-width: '100%';
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.2);
    position: relative;
    border-radius: 10px;
    height: 600px;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  
  .close-btn {
    position: absolute;
    top: 2%;
    right: 3%;
    font-size: 1.5rem;
    z-index: 1;
    color: #fff;
    cursor: pointer;
  }
  .icon{
    width: 40%;
    height:40%;
    margin-left: 30%;
    margin-bottom: 5%;
  }

  .form-content-left {
    background: linear-gradient(
      90deg,
      rgb(39, 176, 255) 0%,
      rgb(0, 232, 236) 100%
    );
    border-radius: 10px 0 0 10px;
    position: relative;
  }
  
  .form-img {
    width: 80%;
    height: 80%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .form-img-2 {
    width: 60%;
    height: 60%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .form-success {
    text-align: center;
    font-size: 24px;
    margin-top: 80px;
    color: #fff;
  }
  
  .form-content-right {
    border-radius: 0 10px 10px 0;
    position: relative;
    background: linear-gradient(90deg, rgb(40, 40, 40) 0%, rgb(17, 17, 17) 100%);
  }
  
  .form {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .form h1 {
    font-size: 1rem;
    text-align: start;
    width: 80%;
    margin-bottom: 1rem;
    color: #fff;
  }
  
  .form-inputs {
    margin-bottom: 0.5rem;
    width: 80%;
  }
  
  .form-inputs p {
    font-size: 0.8rem;
    margin-top: 0.5rem;
    color: #f00e0e;
  }
  
  .form-label {
    display: inline-block;
    font-size: 0.2rem;
    margin-bottom: 6px;
    color: #fff;
  }
  
  .form-input {
    display: block;
    padding-left: 10px;
    outline: none;
    border-radius: 2px;
    height: 40px;
    width: 100%;
    border: none;
  }
  
  .form-input::placeholder {
    color: #595959;
    font-size: 12px;
  }
  
  .form-input-btn {
    width: 80%;
    height: 50px;
    margin-top: 10px;
    border-radius: 2px;
    background: linear-gradient(
      90deg,
      rgb(39, 176, 255) 0%,
      rgb(0, 232, 236) 100%
    );
    outline: none;
    border: none;
    color: #fff;
    font-size: 1rem;
  }
  
  .form-input-btn:hover {
    cursor: pointer;
    background: linear-gradient(
      90deg,
      rgb(39, 143, 255) 0%,
      rgb(12, 99, 250) 100%
    );
    transition: all 0.4s ease-out;
  }
  
  .form-input-login {
    font-size: 0.8rem;
    margin-top: 10px;
    color: #fff;
    width: 80%;
    text-align: center;
  }
  
  .form-input-login a {
    text-decoration: none;
    color: #27cdff;
    font-weight: 600;
  }
  .form-container h1{
      text-align: center;
      color: rgb(253, 252, 249);
      
  }
  .main-text{
    font-weight: 200;
  }
  
.main-input{
  display: none;
}
.btn-info{
  width:100%
}
.loader{
  margin-left: 46%;
  margin-top: 20%;
  position: absolute;
  z-index: 30;
}
.main-text{
  font-size: larger;
  padding-bottom: 10%;
  color: #fff;
  letter-spacing: 20px;
}
@media screen and (max-width: 1024px) {
  .form-container h1{
    margin-top: 65%;
  }
  .main-text{
    font-weight: 400;
    font-size:x-large;
    padding-top: 80%;
    padding-bottom: 10%;
    color: #fff;
    letter-spacing: 20px;
  }
  .content-text{
    color:#595959;
    font-size:x-large;
    padding-bottom: 5%;
  }
  .form-content-right{
    width:auto;
  }
  .icon{
    padding-top: 5%;
  }
  .form-container {
    width:100%;
    height: 100vh;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.2);
    position: relative;
    border-radius: 10px;
    display: grid;
    grid-template-columns: 1fr;
  }
  .form-content-left{
    display:none !important;
  }
  .form {
    position: absolute;
    top: 20%;
    left: 50%;
    width: 100%;
    height: 100%;
  }
  .loader{
    margin-top:40%;
    margin-right: auto;
  }
}
@media screen and (max-width: 480px) {
 
  .main-text{
    font-weight: 80;
    font-size:large;
    padding-top: 80%;
    letter-spacing: 12px;
  }
  .form-content-right{
    width:auto;
  }
  .icon{
    padding-top: 5%;
  }
  .form-container {
    width:100%;
    height: 100vh;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.2);
    position: relative;
    border-radius: 10px;
    display: grid;
    grid-template-columns: 1fr;
  }
  .form-content-left{
    display:none !important;
  }
  .form {
    position: absolute;
    top: 20%;
    left: 50%;
    width: 100%;
    height: 100%;
  }
  .loader{
    margin-top:40%;
    margin-right: auto;
  }
  
}